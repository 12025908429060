import { Box, Link, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SvgResizer from 'react-svg-resizer';

import { ReactComponent as AppGalleryIcon } from "../app/images/AppGallery.svg"
import { ReactComponent as AppStoreIcon } from "../app/images/AppStore.svg"
import { ReactComponent as GooglePlayIcon } from "../app/images/GooglePlay.svg"
import { ReactComponent as RuStoreIcon } from "../app/images/RuStore.svg"

import { meetStart, meetShowWebinarReg, meetWebinarReg, eventIamHidden } from './actions';
import { isAndroid, isIOS } from './functions';
import meetStatuses from './meetStatuses';

const ModeratorMessage = styled(Typography)(() => ({
    display: "inline",
}))

export const MobileLinkText = styled(Typography)(() => ({
    display: "inline",
}))

export const MobileLinkHref = styled(Link)(() => ({
    color: "inherit !important",
    lineHeight: "normal !important",
    textDecorationColor: "inherit !important",
}))

export const NewAppNotification = styled(Typography)(() => ({
    backgroundColor: "rgba(245, 0, 87, 0.1)",
    color: "#f50057",
    fontWeight: "600",
    margin: "15px 0",
    padding: "5px",
    whiteSpace: "pre-line",
}))

const AppInstalledQuestion = styled(Typography)(() => ({
    marginTop: "15px",
}))

export const AppDownloadBox = styled(Box)(() => ({
    alignItems: "start",
    display: "flex",
    gap: "5px",
    justifyContent: "center",
    marginBottom: "15px",
    marginTop: "5px",
}))

export const AppBadgesBox = styled(Box)(() => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    justifyContent: "center",
}))

export const AppBadge = ({ href, icon}) => (
    <MobileLinkHref href={href} rel="noreferrer" target="_blank">
        <SvgResizer size={36}>{icon}</SvgResizer>
    </MobileLinkHref>
)

const MobileLinkClickedBox = styled(Box)(() => ({
    margin: "15px 0",
}))

function Links({ meetId, webinar, isPersonalWebinarLink }) {
    const { _meet, _settings, _joinHiddenEnabled } = useSelector(state => {
        return {
            _meet: state.meet,
            _settings: state.app.settings,
            _joinHiddenEnabled: state.meet.info.joinHiddenEnabled,
        }
    })
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [mobileLinkClicked, setMobileLinkClicked] = useState(false)

    const mintApp = typeof _settings?.mintApp === "object" ? _settings.mintApp : {}
    const appGallery = typeof mintApp.appGallery === "string" ? mintApp.appGallery.trim() : undefined
    const appStore = typeof mintApp.appStore === "string" ? mintApp.appStore.trim() : undefined
    const googlePlay = typeof mintApp.googlePlay === "string" ? mintApp.googlePlay.trim() : undefined
    const ruStore = typeof mintApp.ruStore === "string" ? mintApp.ruStore.trim() : undefined
    const scheme = typeof mintApp.scheme === "string" ? mintApp.scheme.trim() : undefined

    const showAndroidLink = isAndroid() && scheme && (appGallery || googlePlay || ruStore)
    const showIOSLink = isIOS() && scheme && appStore
    const showMobileLink = !webinar && (showAndroidLink || showIOSLink)

    const MobileLink = () => (
        <MobileLinkText>
            {`${t("meet.mobile.link.text")} `}
            <MobileLinkHref
                href="#"
                onClick={(event) => {
                    event.preventDefault()
                    dispatch(meetStart(meetId, true))
                    setMobileLinkClicked(true) }
                }
                rel="noreferrer"
            >
                {t("meet.mobile.link.joinText")}
            </MobileLinkHref>
        </MobileLinkText>
    )

    const MobileBrowserLink = () => (
        <MobileLinkText>
            {`${t("meet.mobile.browserLink.text")} `}
            <MobileLinkHref
                href="#"
                onClick={(event) => {
                    event.preventDefault()
                    dispatch(meetStart(meetId)) }
                }
                rel="noreferrer"
            >
                {t("meet.mobile.browserLink.joinText")}
            </MobileLinkHref>
        </MobileLinkText>
    )

    const renderLinks = () => {
        if (showMobileLink) {
            return (
                <>
                    { mobileLinkClicked
                        ? <NewAppNotification>{t("meet.mobile.newAppNotification")}</NewAppNotification>
                        : <MobileLink />
                    }

                    { mobileLinkClicked
                        ? null
                        : <AppInstalledQuestion>
                            {t('meet.mobile.appInstalledQuestion')}
                        </AppInstalledQuestion>
                    }

                    <AppDownloadBox>
                        <Typography>{t('meet.mobile.downloadFrom')}</Typography>
                        <AppBadgesBox>
                            { isIOS()
                                ? <AppBadge href={appStore} icon={<AppStoreIcon />} />
                                : ( <>
                                    { appGallery ? <AppBadge href={appGallery} icon={<AppGalleryIcon />} /> : null }
                                    { googlePlay ? <AppBadge href={googlePlay} icon={<GooglePlayIcon />} /> : null }
                                    { ruStore ? <AppBadge href={ruStore} icon={<RuStoreIcon />} /> : null }
                                </> )
                            }
                        </AppBadgesBox>
                    </AppDownloadBox>

                    {mobileLinkClicked ? <MobileLinkClickedBox><MobileLink /></MobileLinkClickedBox> : null}

                    <MobileBrowserLink />
                </>
            )
        }

        return (
            <>
                {`${t('meet.showLink.message1')} `}
                <a
                    href="#"
                    onClick={
                        event => {
                            event.preventDefault();
                            if (webinar) {
                                if (isPersonalWebinarLink) {
                                    dispatch(meetWebinarReg(true, true));
                                } else {
                                    dispatch(meetShowWebinarReg());
                                }
                            } else {
                                dispatch(meetStart(meetId)); // Because it's PC
                            }
                        }
                    }
                >
                    {t('meet.showLink.message2')}
                </a>
                {_joinHiddenEnabled ?
                    <Box sx={{ mt: 4 }}>
                        <a
                            href="#"
                            onClick={
                                event => {
                                    event.preventDefault();
                                    dispatch(eventIamHidden())
                                    if (webinar) {
                                        if (isPersonalWebinarLink) {
                                            dispatch(meetWebinarReg(true, true));
                                        } else {
                                            dispatch(meetShowWebinarReg());
                                        }
                                    } else {
                                        dispatch(meetStart(meetId)); // Because it's PC

                                    }
                                }
                            }
                        >
                            {t('meet.showLink.hidden')}
                        </a>
                    </Box>
                    : null}
            </>
        )
    }


    const { status, info } = _meet;

    switch (status) {
        case meetStatuses.SHOWLINK:
            return (
                <>
                    <ModeratorMessage>
                        { info.jwt
                            ? `${t('meet.moderatorMessage')} `
                            : null
                        }
                    </ModeratorMessage>
                    {renderLinks()}
                </>
            )

        case meetStatuses.NOTSTARTED:
            return (
                <Typography>
                    <b>{`${t('meet.notStarted.message1')}...`}</b>
                    <br />
                    {`${t('meet.notStarted.message2')}.`}
                    <br />
                    <br />
                    {`${t('meet.notStarted.message3')}.`}
                </Typography>
            )

        default:
            return (
                <Typography>
                    {`${t('meet.tooEarly.message1')} `}
                    {`${_settings.showLinkTimeout} `}
                    {`${t('meet.tooEarly.message2')}.`}
                </Typography>
            )
    }
}

export default Links;
