import { ROOM_JOIN } from './actionTypes';
import { backdropToggled } from '../app/actions';
import { isAndroid, isIOS } from '../meet/functions';
import meetStatuses from '../meet/meetStatuses';


export const roomMiddleware = ({ getState, dispatch }) => next => action => {
    const state = getState();
    const { backEnd, settings } = state.app;

    switch( action.type ) {
        case ROOM_JOIN:
            dispatch( backdropToggled( true ) );

            fetch(
                `${ backEnd }/api/meet/start/room/${ action.roomId }`
                + ( Boolean(action.hash) ? `/${ action.hash }` : '' ),
                {
                    credentials: 'include'
                }
            )
            .then( response => { return response.json() } )
            .then(data => {
                if( !data.status ) {
                    throw new Error(`Incorrect response to start meet request: ${ JSON.stringify(data) }`);
                }

                if( data.status === meetStatuses.STARTED && Object.keys(data.meet).length > 0 ) {
                    const mintApp = typeof settings?.mintApp === "object" ? settings.mintApp : {}
                    const appGallery = typeof mintApp.appGallery === "string" ? mintApp.appGallery.trim() : undefined
                    const appStore = typeof mintApp.appStore === "string" ? mintApp.appStore.trim() : undefined
                    const googlePlay = typeof mintApp.googlePlay === "string" ? mintApp.googlePlay.trim() : undefined
                    const ruStore = typeof mintApp.ruStore === "string" ? mintApp.ruStore.trim() : undefined
                    const scheme = typeof mintApp.scheme === "string" ? mintApp.scheme.trim() : undefined

                    const showAndroidLink = isAndroid() && scheme && (appGallery || googlePlay || ruStore)
                    const showIOSLink = isIOS() && scheme && appStore

                    if (showAndroidLink || showIOSLink) {
                        const meetURL =
                            `${ scheme }://${ window.location.host }/${ data.meet._id }`
                            + ( data.meet.jwt ? `?jwt=${ data.meet.jwt }` : `` );
                        window.location = meetURL;
                        action.status = null;
                    } else {
                        action.status = meetStatuses.STARTED;
                    }

                    action.info = data.meet;
                } else if(
                    [
                        'roomId_incorrect',
                        meetStatuses.ERROR,
                        meetStatuses.NOTFOUND
                    ].includes( data.status )
                ) {
                    action.status = data.status;
                } else if(
                    [
                        meetStatuses.PARTICIPANTSLIMIT,
                        meetStatuses.ORGLIMIT,
                        meetStatuses.LICENSEUSED
                    ].includes( data.status )
                    && data.limit
                ) {
                    action.status = data.status;
                    action.limit = data.limit;
                } else {
                    throw new Error(
                        `Incorrect status of start meet request or empty limit value: ${ JSON.stringify(data) }`
                    );
                }
            })
            .catch(() => {
                action.status = meetStatuses.ERROR;
            })
            .finally(() => {
                setTimeout(() => { dispatch( backdropToggled( false ) ) }, 300);
                next(action);
            });

            return;
    }

    return next(action);
}
