import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  InputBase,
  InputLabel,
  Link,
  styled,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { useUsdState } from "../hooks"

const FormContainer = styled(Container)(() => ({
  marginTop: "50px",
}))

const FormCard = styled(Card)(() => ({
  borderRadius: "10px",
}))

const Title = styled(CardHeader)(() => ({
  "& .MuiTypography-root": {
    fontSize: "1rem",
    fontWeight: "500",
    textAlign: "center",
  }
}))

const Label = styled(InputLabel)(() => ({
  fontSize: "0.9rem",
}))

const Input = styled(InputBase)(() => ({
  border: "1px solid #dcdcdc",
  borderRadius: "10px",
  padding: "5px 10px",
}))

const HelperText = styled(FormHelperText)(() => ({
  wordBreak: "break-word",
}))

const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "5px",
}))

const JoinButton = styled(Button)(() => ({}))

const Agreement = styled(FormControlLabel)(() => ({
  "marginTop": "20px",
  ".MuiTypography-root": {
    fontSize: "0.8rem",
  },
}))

const AgreementLink = styled(Link)(() => ({
  color: "#e30016",
  textDecoration: "none",
}))

const Form = () => {
  const { t } = useTranslation()

  const {
    joinButtonDisabled,
    joinError,
    joinStatus,
    meetNumberLength,
    state,
    submit,
    updateState,
    waitingToJoin,
  } = useUsdState()

  const { agreement, meetNumber } = state

  return (
    <FormContainer maxWidth="sm">
      <form onSubmit={(event) => event.preventDefault()}>
        <FormCard>
          <Title title={t("usd.form.card.title")} />
          <CardContent>
            <Label htmlFor="meetNumber">{t("usd.form.field.meetNumber.label")}</Label>
            <Input
              autoComplete="off"
              disabled={waitingToJoin}
              fullWidth
              id="meetNumber"
              name="meetNumber"
              onChange={(event) => updateState(event.target.name, event.target.value)}
              placeholder={
                t("usd.form.field.meetNumber.placeholder", { meetNumberLength })
              }
              value={meetNumber}
            />
            <HelperText
              error={joinError}
            >
              {joinStatus ? t(`usd.form.status.${joinStatus}`) : " "}
            </HelperText>
            <ButtonBox>
              <JoinButton
                disabled={joinButtonDisabled}
                onClick={() => submit()}
                variant="contained"
              >
                {t("usd.form.field.joinButton.text")}
              </JoinButton>
            </ButtonBox>
            <Agreement
              control={
                <Checkbox
                  checked={agreement}
                  disabled={waitingToJoin}
                  name="agreement"
                  onChange={(event) => updateState(event.target.name, !agreement)}
                />
                }
              label={
                <>
                  {t("usd.form.field.agreement.label")}
                  <AgreementLink href="https://webvks.sudrt.ru/custom/rules.pdf" rel="noreferrer" target="_blank">
                    {t("usd.form.field.agreement.linkLabel")}
                  </AgreementLink>
                </>
              }
            />
          </CardContent>
        </FormCard>
      </form>
    </FormContainer>
  )
}

export default Form
