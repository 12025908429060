import { UAParser } from "ua-parser-js"
import { isChromeFamily } from "ua-parser-js/helpers"

const result = UAParser()

export const isAndroid = () => {
    return result.os.is("Android")
}

export const isBrowserOk = () => {
    return isAndroid()
        || isIOS()
        || isChromeFamily(result)
        || result.browser.is("Firefox")
        || result.browser.is("Safari")
}

export const isBrowserWarning = () => {
    return result.browser.is("Firefox") || result.browser.is("Safari")
}

export const isIOS = () => {
    return result.os.is("iOS")
}

export const isMobile = () => {
    return  isAndroid() || isIOS()
}
