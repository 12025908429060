import {
    Button,
    Container,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as AppGalleryIcon } from "../app/images/AppGallery.svg"
import { ReactComponent as AppStoreIcon } from "../app/images/AppStore.svg"
import { ReactComponent as GooglePlayIcon } from "../app/images/GooglePlay.svg"
import { ReactComponent as RuStoreIcon } from "../app/images/RuStore.svg"
import { isIOS } from '../meet/functions'
import {
    AppBadge,
    AppBadgesBox,
    AppDownloadBox,
    MobileLinkHref,
    MobileLinkText,
    NewAppNotification,
} from '../meet/Links'

import { roomJoin, roomStartMobileIframe } from './actions';

function JoinRoom({ roomId, hash}) {
    const [stateRoomId, setStateRoomId] = useState(roomId)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { _room, _settings } = useSelector(state => {
        return {
            _room: state.room,
            _settings: state.app.settings
        }
    })
    function joinRoom(event) {
        event.preventDefault();
        dispatch(roomJoin(stateRoomId, hash));
    }

    const { info, status, limit } = _room;

    const MobileBrowserLink = () => (
        <MobileLinkText>
            {`${t("meet.mobile.browserLink.text")} `}
            <MobileLinkHref
                href="#"
                onClick={(event) => {
                    event.preventDefault()
                    dispatch(roomStartMobileIframe()) }
                }
                rel="noreferrer"
            >
                {t("meet.mobile.browserLink.joinText")}
            </MobileLinkHref>
        </MobileLinkText>
    )

    const renderLinks = () => {
        if (Object.keys(info).length) {
            const { appGallery, appStore, googlePlay, ruStore } = _settings.mintApp

            return (
                <>
                    <NewAppNotification>{t("meet.mobile.newAppNotification")}</NewAppNotification>

                    <AppDownloadBox>
                        <Typography>{t('meet.mobile.downloadFrom')}</Typography>
                        <AppBadgesBox>
                            { isIOS()
                                ? <AppBadge href={appStore} icon={<AppStoreIcon />} />
                                : ( <>
                                    { appGallery ? <AppBadge href={appGallery} icon={<AppGalleryIcon />} /> : null }
                                    { googlePlay ? <AppBadge href={googlePlay} icon={<GooglePlayIcon />} /> : null }
                                    { ruStore ? <AppBadge href={ruStore} icon={<RuStoreIcon />} /> : null }
                                </> )
                            }
                        </AppBadgesBox>
                    </AppDownloadBox>

                    <MobileBrowserLink />
                </>
            )
        }

        return null
    }

    return (
        <Container maxWidth="sm">
            <form onSubmit={event => { joinRoom(event) }}>
                <InputMask
                    mask="99-99-99"
                    disabled={Boolean(roomId) && Boolean(hash)}
                    value={stateRoomId}
                    onChange={
                        event => {
                            setStateRoomId(event.target.value)
                        }
                    }
                >
                    {
                        () =>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="dense"
                                fullWidth
                                required
                                label={t('room.roomId')}
                                name="roomId"
                                disabled={Boolean(roomId) && Boolean(hash)}
                                error={Boolean(status)}
                                helperText={Boolean(status) ? t(`room.${status}`, { limit }) : ''}
                            />
                    }
                </InputMask>

                <br />
                <br />

                <Button variant="contained" type="submit">{t('room.join')}</Button>
            </form>

            {renderLinks()}
        </Container>
    )
}

export default JoinRoom;
